<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      selectedTap: true,
      configurations: [],
      customers: [],
      Customers: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchCustomers: null,
      CustomersSearchMode: false,
      tariff: [],
      neighborhood: [],
      addOptions: [{}],
      editOptions: [{}],
      option: "",
      data: {},
      config: [],
      editModalData: {},
      customer: {},
      edit: [],
      optionsData: [],
      newOptions: [],
      select: [],
      branch_id: null,

      // appprices:[]
    };
  },
  methods: {
    editCustomers(app) {
      console.log(app);
      this.edit = app;
    },
    editconfigdata(config) {
      this.editModalData = config;
      console.log("********", config);
      this.config = Object.assign([], config);
    },
    onChange(event) {
      console.log("_________", event.target.value);
      if (event.target.value == "select") {
        return (this.option = 3);
      }
    },
    addconfig() {
      if (this.data.option == "select") {
        this.http
          .post("emps/add-col", {
            table: "customers",
            data: this.data,
            options: this.addOptions,
          })
          .then(() => {
            this.getconfig(this.page);
          });
      } else {
        console.log("in else");
        console.log(this.data);
        this.http
          .post("emps/add-col", {
            table: "customers",
            data: this.data,
          })
          .then(() => {
            this.getconfig(this.page);
          });
      }
    },
    editconfig() {
      console.log(")))*****", this.addOptions);
      if (this.editModalData.option == "select") {
        this.http
          .post("emps/edit-col", {
            table: "customers",
            options: this.editOptions,
            config: this.editModalData,
            config_id: this.editModalData.id,
          })
          .then(() => {
            this.get(1);
          });
      } else {
        delete this.editModalData.options;
        this.http
          .post("emps/edit-col", {
            table: "customers",
            config: this.editModalData,
            config_id: this.editModalData.id,
          })
          .then(() => {
            this.get(1);
          });
      }
    },
    deleteconfig(config) {
      var data = {
        title: "popups.deletecol",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("table-fileds", config.id).then(() => {
            console.log("############################3");
            this.getconfig(this.page);
          });
        }
      });
    },
    addCustomers() {
      console.log("//////", this.configurations);
      this.http
        .post("customers", {
          data: this.customer,
          types: this.configurations,
          branch_id: this.branch_id,
        })
        .then(() => {
          this.get(1);
          // this.config = {};
          // this.addOptions = [{}];
        });
    },
    search() {
      this.CustomersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("customers/search", {
          search: this.searchModel,
          col: "name",
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          this.customers = res.data;
          this.customers.forEach((customer, index) => {
            let code = (this.page - 1) * this.limit + index + 1;
            customer.code =
              "CUS-" +
              (code < 10
                ? "000" + code
                : code < 100
                ? "00" + code
                : code < 1000
                ? "0" + code
                : code);
          });
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.CustomersSearchMode = false;
      this.get(this.page);
    },
    getCustomers() {
      this.http.get("customers").then((res) => {
        this.customers = res.data;
      });
    },
    gitTariff() {
      this.http.get("tariff").then((res) => {
        this.tariff = res.data.map((t) => {
          return { value: t.id, lable: t.name, lable_en: t.name_en };
        });
        console.log("/////////,.,.,.,", this.tariff);
      });
    },
    gitleveltwo() {
      this.http.get("level-two-chart-of-accounts").then((res) => {
        this.leveltwo = res.data;
      });
    },

    gitNeighborhood() {
      this.http.post("neighborhoods/branch", {}).then((res) => {
        this.neighborhood = res.data.map((t) => {
          return { value: t.id, lable: t.name, lable_en: t.name_en };
        });
        console.log("customer", this.neighborhood);
      });
    },
    editCustomerData() {
      console.log("????");
      let data = this.edit;
      this.http.put("customers", data.id, data).then(() => {
        this.get(1);
        // this.config = {};
        // this.addOptions = [{}];
      });
    },
    deleteCustomers(app) {
      var data = {
        title: "popups.deletcustomer",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("customers", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    getSwitch(app, status) {
      console.log("*********", status);
      this.http
        .post("customers/update-status", {
          status: status,
          customer_id: app.id,
        })

        .then(() => {
          this.get(this.page);
        });
    },

    get(page) {
      this.http
        .post("customers/pagination", {
          limit: this.limit,
          page: page,
          table: "customers",
          tables: ["customers", "table_fileds", "neighborhoods", "tariff"],
          fields: [
            "customers.*",
            "neighborhoods.name as neighborhood_name ",
            "table_fileds.name as name_en",
            "table_fileds.name_ar as name_ar",
            "table_fileds.options as options",
            "tariff.name as tariff_name",
          ],
          where: [
            "table_fileds.table='customers'",
            "neighborhoods.id=customers.neighborhood_id ",
            "tariff.id=customers.tariff_id",
          ],
          relations: [
            {
              table: "customers",
              key: "neighborhood_id",
            },
            {
              table: "customers",
              key: "tariff_id",
            },
          ],
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.customers = res.data;
          this.customers.forEach((customer, index) => {
            let code = (this.page - 1) * this.limit + index + 1;
            customer.code =
              "CUS-" +
              (code < 10
                ? "000" + code
                : code < 100
                ? "00" + code
                : code < 1000
                ? "0" + code
                : code);
          });
          console.log("%%%%5", this.customers);
          this.http
            .post("emps/get-cols", {
              table: "customers",
            })
            .then((res) => {
              console.log("/////////////", res.data);

              this.configurations = res.data;
              console.log("*******////", this.configurations);
              this.configurations.forEach((item) => {
                this.customers.forEach((customerIndex) => {
                  if (item.table == "customers") {
                    customerIndex["extra"] = customerIndex[[item.field]];
                  }
                });
              });
              // for (let j = 0; j < this.configurations.length; j++) {
              //   // alert("name>>>>>>>>>>>" + this.configurations[j])
              //   if (this.configurations[j].table == "customers") {
              //     for (let i = 0; i < this.customers.length; i++) {
              //       this.customers[i]["filed" + i] = this.customers[i][[this.configurations[j].field]]

              //     }
              //   }
              // }
              console.log("--------------------------------", this.customers);
            });
        });
    },
    getconfig(page) {
      console.log(page);
      this.http
        .post("emps/get-cols", {
          table: "customers",
        })
        .then((res) => {
          this.configurations = res.data;
          for (let i = 0; i < this.configurations.length; i++) {
            this.configurations[i].options = JSON.parse(
              this.configurations[i].options
            );
            if (this.configurations[i].type == "int") {
              this.configurations[i].type = "number";
            }
          }
          console.log("//////*******////", this.configurations);
        });
    },

    // console.log(page)
    // this.http.post('emps/get-cols', {
    //   table: "customers"
    // }).then((res) => {
    //   console.log("hhhhh", res.data)

    //   this.configurations = res.data

    //   for (let i = 0; i < this.configurations.length; i++) {
    //     if (this.configurations[i].type == 'select') {
    //       this.configurations[i].options = JSON.parse(this.configurations[i].options)
    //       this.select.push(this.configurations[i].options)
    //     }
    //   }

    //   console.log("///////*****///", this.select)
    //   this.data = {};
    //   this.addOptions = [{}];

    // loopOnArray() {

    //   // alert(this.configurations.length)
    //   for (let j = 0; j < this.configurations.length; j++) {
    //     // alert("name>>>>>>>>>>>" + this.configurations[j])
    //     for (let i = 0; i < this.customers.length; i++) {
    //       if (this.configurations[j].table == "customers") {
    //         this.customers[i][[this.configurations[j].name_ar]].push(this.customers[i][[this.configurations[j].filed]]
    //         )
    //       }
    //     }
    //   }
    //   console.log("*******************<<<<<<<<<<<<", this.customers)
    // }
  },
  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    this.get(1);
    // this.getCustomers();
    this.getconfig();
    this.gitTariff();
    this.gitNeighborhood();

    // this.loopOnArray()
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.customers.text')"
    />

    <!-- <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    > -->
    <!-- <b-tab> -->
    <!-- <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("menu.menuitems.customers.text")
          }}</span>
        </template> -->

    <div
      :dir="$i18n.locale == 'ar' ? 'ltr' : 'ltr'"
      class="row"
      v-if="selectedTap"
    >
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="search-box chat-search-box w-50">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control fa-lg text-light"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                      style="background-color: #2a3042 !important"
                    />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <button
                  class="btn-close text-light"
                  v-if="CustomersSearchMode"
                  @click="cancelappsearchMode()"
                ></button>
                <button
                  type="button"
                  @click="selectedTap = !selectedTap"
                  class="btn btn-light float-end mb-4 mx-2"
                >
                  <span
                    class="bx bxs-cog float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>

                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#addcustomer"
                  class="btn btn-light float-end mb-4"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                class="table table-centered table-nowrap table-striped table-hover align-middle"
                v-if="selectedTap"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("popups.code") }}</th>
                    <th scope="col">{{ $t("customers.name") }}</th>
                    <th scope="col">{{ $t("customers.tariff") }}</th>
                    <th scope="col">{{ $t("customers.neighborhood") }}</th>
                    <!-- <th scope="col">{{ $t("customers.indebtedness") }}</th> -->
                    <th scope="col">{{ $t("customers.meter_no") }}</th>
                    <th scope="col">{{ $t("customers.account_no") }}</th>
                    <th scope="col">{{ $t("customers.house_no") }}</th>
                    <th scope="col">{{ $t("customers.block") }}</th>
                    <th scope="col">{{ $t("customers.degree") }}</th>
                    <th scope="col">{{ $t("customers.add_date") }}</th>
                    <th scope="col" v-for="col in configurations" :key="col">
                      {{
                        $i18n.locale == "ar"
                          ? col.name_ar
                            ? col.name_ar
                            : col.name
                          : col.name
                          ? col.name
                          : col.name_ar
                      }}
                    </th>
                    <th scope="col">{{ $t("customers.created") }}</th>
                    <th scope="col">{{ $t("customers.updated") }}</th>
                    <th scope="col">{{ $t("customers.operations") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(app, index) in customers" :key="app" class="">
                    <td>{{ index + 1 }}</td>
                    <!-- <td>{{ app.code }}</td> -->
                    <td>{{ app?.code }}</td>
                    <td>{{ app.name }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                          ? app?.tariff_name
                            ? app?.tariff_name
                            : app?.tariff_name_en
                          : app?.tariff_name_en
                          ? app?.tariff_name_en
                          : app?.tariff_name
                      }}
                    </td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                          ? app?.neighborhood_name
                            ? app?.neighborhood_name
                            : app?.neighborhood_name_en
                          : app?.neighborhood_name_en
                          ? app?.neighborhood_name_en
                          : app?.neighborhood_name
                      }}
                    </td>
                    <!-- <td>
                      {{
                        (
                          parseFloat(app?.totalRecivableDebit) -
                          parseFloat(app?.totalRecivableCredit) +
                          parseFloat(app?.totalUnearnedCredit) -
                          parseFloat(app?.totalUnearnedDebit)
                        ).toLocaleString()
                      }}
                    </td> -->
                    <td>{{ app.meter_no }}</td>
                    <td>{{ app.account_no }}</td>
                    <td>{{ app.house_no }}</td>
                    <td>{{ app.block }}</td>
                    <td>{{ app.degree }}</td>
                    <td>{{ app.add_date?.split("T")[0] }}</td>
                    <td v-for="col in configurations" :key="col">
                      {{ app[col.field] }}
                    </td>
                    <td>{{ app.created.split("T")[0] }}</td>
                    <td>{{ app.updated.split("T")[0] }}</td>
                    <td>
                      <button
                        @click="getSwitch(app, 1)"
                        v-if="app.status == 0"
                        style="margin-inline-end: 4px"
                        class="btn btn-success btn-sm"
                      >
                        {{ $t("popups.enable") }}
                      </button>
                      <button
                        style="margin-inline-end: 4px"
                        type="button"
                        @click="getSwitch(app, 0)"
                        v-if="app.status == 1"
                        class="btn btn-secondary btn-sm"
                      >
                        {{ $t("popups.disable") }}
                      </button>

                      <button
                        style="margin-inline-end: 4px"
                        class="btn btn-primary btn-sm"
                        @click="editCustomers(app)"
                        data-bs-toggle="modal"
                        data-bs-target="#editcustomer"
                        role="button"
                      >
                        {{ $t("popups.edit") }}
                      </button>
                      <button
                        class="btn btn-danger btn-sm"
                        role="button"
                        @click="deleteCustomers(app)"
                      >
                        {{ $t("popups.delete") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!--   Apps  Pagination     -->
    <!-- stop pagination -->
    <div v-if="selectedTap">
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- stop pagination -->

    <!--end-->

    <!--emp tab-->
    <!-- </b-tab> -->
    <!-- <b-tab :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">
            {{ $t("popups.configuration") }}
          </span>
        </template> -->
    <!--conf-->
    <!-- <div class="row">
                                                                            <div class="col-6">
                                                                              <div class="search-box chat-search-box w-50">
                                                                           <div class="position-relative">
                                                                                  <input @keyup="search()" type="text" class="form-control fa-lg text-light"
                                                                                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.serarch')" v-model="searchModel"
                                                                                    style="background-color: #2a3042 !important; wdith:250px !important">
                                                                                  <i class="bx bx-search-alt search-icon  text-light"></i>
        
                                                                                </div>
                                                                              </div>
                                                                            </div> -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12" v-if="!selectedTap">
                <button
                  class="btn-close text-light"
                  v-if="EmpsSearchMode"
                  @click="cancelappsearchMode()"
                ></button>
                <button
                  type="button"
                  class="btn btn-light float-end mb-4 mx-2"
                  data-bs-toggle="modal"
                  data-bs-target="#add"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>

                <button
                  type="button"
                  @click="selectedTap = !selectedTap"
                  class="btn btn-light float-end mb-4"
                >
                  <span
                    class="bx bxs-user float-start fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>

            <div class="table-responsive">
              <table
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                class="table table-striped table-hover mb-0"
                v-if="!selectedTap"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("config.ar") }}</th>
                    <th scope="col">{{ $t("config.en") }}</th>
                    <th scope="col">{{ $t("config.operations") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(config, index) in configurations"
                    :key="config"
                    class=""
                  >
                    <td>{{ index + 1 }}</td>

                    <td>{{ config?.name_ar }}</td>
                    <td>{{ config?.name }}</td>

                    <td class="d-flex">
                      <a
                        class="btn btn-primary me-2"
                        @click="editconfigdata(config)"
                        href="javascript: void(0);"
                        role="button"
                        data-bs-toggle="modal"
                        data-bs-target="#edit"
                        >{{ $t("popups.edit") }}</a
                      >
                      <a
                        class="btn btn-danger me-2"
                        href="javascript: void(0);"
                        role="button"
                        @click="deleteconfig(config)"
                        >{{ $t("popups.delete") }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </b-tab></b-tabs -->
    <!-- > -->
    <!--add customers-->
    <div
      class="modal fade"
      id="addcustomer"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container px-0">
              <form @submit.prevent="addCustomers()">
                <div class="modal-header">
                  <h5 class="modal-title mt-3" id="exampleModalLabel">
                    {{ $t("popups.adacol") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="position: relative; left: 10px"
                  ></button>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("popups.tariff") }}
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="customer.tariff_id"
                  >
                    <option v-for="i in this.tariff" :key="i" :value="i.value">
                      {{ $i18n.locale == "ar" ? i?.lable : i?.lable_en }}
                    </option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.neighborhood") }}
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="customer.neighborhood_id"
                  >
                    <option
                      v-for="i in this.neighborhood"
                      :key="i"
                      :value="i.value"
                    >
                      {{ $i18n.locale == "ar" ? i?.lable : i?.lable_en }}
                    </option>
                  </select>
                </div>

                <label for="exampleInputEmail1" class="form-label">{{
                  $t("popups.name")
                }}</label>
                <input
                  type="text"
                  v-model="customer.name"
                  class="form-control"
                  id="exampleInputPassword1"
                />

                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.meterno") }}
                  </label>
                  <input
                    type="text"
                    v-model="customer.meter_no"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.accountno") }}
                  </label>
                  <input
                    type="text"
                    v-model="customer.account_no"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.houseno") }}
                  </label>
                  <input
                    type="text"
                    v-model="customer.house_no"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.block") }}
                  </label>
                  <input
                    type="text"
                    v-model="customer.block"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.degree") }}
                  </label>
                  <input
                    type="text"
                    v-model="customer.degree"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.adddate") }}
                  </label>
                  <input
                    type="date"
                    v-model="customer.add_date"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <!-- new col -->
                <div class="mb-3" v-for="i in configurations" :key="i">
                  <div v-if="i.type != 'select'">
                    <label for="exampleInputPassword1" class="form-label"
                      >{{ i.name }}
                    </label>
                    <input
                      :type="i.type"
                      v-model="i.extra"
                      class="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>

                  <div v-else>
                    <label for="exampleInputPassword1" class="form-label">{{
                      i.name
                    }}</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="i.edit"
                    >
                      <option
                        v-for="item in i.options"
                        :key="item"
                        :value="item.oneoption"
                      >
                        {{ item?.oneoption }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="modal-footer"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("popups.add") }}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-danger"
                  >
                    {{ $t("popups.cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end add customer-->

    <!--edit customer-->
    <div
      class="modal fade"
      id="editcustomer"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container px-0">
              <form @submit.prevent="editCustomerData()">
                <div class="modal-header">
                  <h5 class="modal-title mt-3" id="exampleModalLabel">
                    {{ $t("popups.adacol") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="position: relative; left: 10px"
                  ></button>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("popups.tariff") }}
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="edit.tariff_id"
                  >
                    <option v-for="i in this.tariff" :key="i" :value="i.value">
                      {{ i.lable }}
                    </option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.neighborhood") }}
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="edit.neighborhood_id"
                  >
                    <option
                      v-for="i in this.neighborhood"
                      :key="i"
                      :value="i.value"
                    >
                      {{ i.lable }}
                    </option>
                  </select>
                </div>

                <label for="exampleInputEmail1" class="form-label">{{
                  $t("popups.name")
                }}</label>
                <input
                  type="text"
                  v-model="edit.name"
                  class="form-control"
                  id="exampleInputPassword1"
                />

                <!-- <div class="mb-3" v-for="i in configurations" :key="i">
                  <div v-if="i.options != null">
                    <label for="exampleInputPassword1" class="form-label">{{
                      i.options
                    }}</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="edit.name"
                    >
                      <option
                        v-for="x in i.options"
                        :key="x"
                        :value="x.oneoption"
                      >
                        {{ x.oneoption }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-3" v-for="i in configurations" :key="i">
                  <label for="exampleInputPassword1" class="form-label">{{
                    i.name_ar
                  }}</label>
                  <input :type="i.type" v-model="col" class="form-control" />
                </div> -->
                <div class="mb-3" v-for="i in configurations" :key="i">
                  <div v-if="i.type != 'select'">
                    <label for="exampleInputPassword1" class="form-label"
                      >{{ i.name }}
                    </label>
                    <input
                      :type="i.type"
                      v-model="i.extra"
                      class="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>

                  <div v-else>
                    <label for="exampleInputPassword1" class="form-label">{{
                      i.name
                    }}</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="i.edit"
                    >
                      <option
                        v-for="item in i.options"
                        :key="item"
                        :value="item.oneoption"
                      >
                        {{ item?.oneoption }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.meterno") }}
                  </label>
                  <input
                    type="text"
                    v-model="edit.meter_no"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.accountno") }}
                  </label>
                  <input
                    type="text"
                    v-model="edit.account_no"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.houseno") }}
                  </label>
                  <input
                    type="text"
                    v-model="edit.house_no"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.block") }}
                  </label>
                  <input
                    type="text"
                    v-model="edit.block"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.degree") }}
                  </label>
                  <input
                    type="text"
                    v-model="edit.degree"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("popups.adddate") }}
                  </label>
                  <input
                    type="date"
                    v-model="edit.add_date"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div
                  class="modal-footer"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("popups.edit") }}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-danger"
                  >
                    {{ $t("popups.cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end edit customer-->

    <!--start add new col modal-->
    <div
      class="modal fade"
      id="add"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container px-0">
              <form @submit.prevent="addconfig()">
                <div class="modal-header">
                  <h5 class="modal-title mt-3" id="exampleModalLabel">
                    {{ $t("popups.adacol") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="position: relative; left: 10px"
                  ></button>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label"
                    >Arbic Name</label
                  >
                  <input
                    type="text"
                    v-model="data.ar"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label"
                    >English Name</label
                  >
                  <input
                    type="text"
                    v-model="data.en"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <label for="exampleInputEmail1" class="form-label">Type</label>
                <select
                  class="form-select"
                  @change="onChange($event)"
                  v-model="data.option"
                  aria-label="Default select example"
                >
                  <option value="number">Number</option>
                  <option value="text">Text</option>
                  <option value="date">Date</option>
                  <option value="select">Options</option>
                </select>
                <div v-if="this.option == 3">
                  <button
                    type="button"
                    @click="addOptions.push({})"
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x mb-2 mt-2"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                  <div v-for="option in addOptions" :key="option">
                    <input
                      type="text"
                      v-model="option.oneoption"
                      class="form-control mb-2"
                      id="exampleInputPassword1"
                    />
                  </div>
                </div>
                <div
                  class="modal-footer"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("popups.add") }}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-danger"
                  >
                    {{ $t("popups.cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end add new col modal-->

    <!--start edit new col modal-->
    <div
      class="modal fade"
      id="edit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container px-0">
              <form @submit.prevent="editconfig()">
                <div class="modal-header">
                  <h5 class="modal-title mt-3" id="exampleModalLabel">
                    {{ $t("popups.adacol") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="position: relative; left: 10px"
                  ></button>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label"
                    >Arbic Name</label
                  >
                  <input
                    type="text"
                    v-model="editModalData.name_ar"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label"
                    >English Name</label
                  >
                  <input
                    type="text"
                    v-model="editModalData.name"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <label for="exampleInputEmail1" class="form-label">Type</label>
                <select
                  class="form-select"
                  @change="onChange($event)"
                  v-model="editModalData.option"
                  aria-label="Default select example"
                >
                  <option value="number">Number</option>
                  <option value="text">text</option>
                  <option value="date">Date</option>
                  <option value="select">Options</option>
                </select>
                <div v-if="this.option == 3">
                  <button
                    type="button"
                    @click="editOptions.push({})"
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x mb-2 mt-2"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                  <div v-for="option in editOptions" :key="option">
                    <input
                      type="text"
                      v-model="option.oneoption"
                      class="form-control mb-2"
                      id="exampleInputPassword1"
                    />
                  </div>
                </div>
                <div
                  class="modal-footer"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("popups.edit") }}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-danger"
                  >
                    {{ $t("popups.cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end edit new col modal-->
  </Layout>
</template>
<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
